import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

/* MobX */
import { observer } from 'mobx-react-lite';

import { getAppealByAccount, submitLegalOwnerAppeal, submitImpermeableAreaAppeal, submitExcludedAreaAppeal, submitSAAppeal } from '../services/APIClient'

/* MUI */
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Box, Button, Container, Divider, Grid, Paper, Typography, LinearProgress, FormControl } from '@material-ui/core';

/* Langan Components */
import GeneralInformation from "../components/appeal/appeal-form/GeneralInformation";
import LegalOwnerAppeal from "../components/appeal/appeal-form/LegalOwnerAppeal";
import ImpermeableAreaAppeal from "../components/appeal/appeal-form/ImpermeableAreaAppeal";
import ExcludedAreaAppeal from "../components/appeal/appeal-form/ExcludedAreaAppeal";
import ServiceAgreementAppeal from "../components/appeal/appeal-form/ServiceAgreementAppeal";
import { validateArea } from '../components/utils/utils';
import { LinkTextDisplay } from '../components/data-display/data-display.component';
import CustomDialog from '../components/data-display/dialog.component';
import form from '../assets/files/AppealForm-110723 Revised Ver_FINAL.pdf'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  formRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: "20px",
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: "20px",
    marginBottom: theme.spacing(2),
  },
  buttonSubmit: {
    marginBottom: 10,
  },
  link: theme.link,
}));

const today = new Date();
const TypeOfAccountMatrix = {
  "The service account holder": false,
  "The property owner": false,
  "An approved representative of the owner or account holder": false,
}

const defaultGeneralInfo = {
  CCB_Account: "",
  Zipcode: "",
  ApplicantName: "",
  Applicant_Phone: "",
  Applicant_Email: "",
  TypeOfAccountHolder: [],
};

const defaultArea = {
  S_Current_Gross_Total_Area: 0,
  S_Current_Excluded_Area: 0,
  S_Current_Permeable_Area: 0,
  S_Current_Impermeable_Area: 0
};

const defaultLegalOwner = {
  F_New_Legal_Owner_Name: "",
  F_LO_Appeal_Record_Date: today.toLocaleDateString('en-US'),
  F_Phone_Number: "",
  F_New_Owner_Mailing_Address: "",
  F_New_Owner_Mailing_Address_2: "",
  F_New_Owner_Mailing_City: "",
  F_New_Owner_Mailing_State: "",
  F_New_Owner_Mailing_Zip: "",
  F_New_Owner_Mailing_Country: "USA",
  F_Applicant_Comments_legalOwner: "",
  F_Is_Government_Entity: false,
  r_Decision: '',
};

const defaultImpermeable = {
  F_Appealed_Impermeable_Area: 0,
  F_Appealed_Permeable_Area: 0,
  F_Appealed_Billable_Impermeable_Area: 0,
  F_Applicant_Comments_Impermeable: "",
  r_Decision: '',
};

const defaultExcluded = {
  F_Appealed_Excluded_Area: 0,
  F_Appealed_Billable_Impermeable_Area: 0,
  F_Appealed_Impermeable_Area: 0,
  F_Appealed_Permeable_Area: 0,
  F_Appealed_Billable_Permeable_Area: 0,
  F_Appealed_Net_Total_Area: 0,
  F_Applicant_Comments_Excluded: "",
  r_Decision: '',
};

const defaultChargeAllocations = {
  Accounts: [],
  F_Applicant_Comments_SA: "",
  r_Decision: '',
}

const AppealFormPage = observer(props => {
  const classes = useStyles();
  const navigate = useNavigate();

  // To handle accordian expansion
  const [expanded, setExpanded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);

  // General Information
  const [generalInfo, setGeneralInfo] = useState(defaultGeneralInfo);
  const [area, setArea] = useState(defaultArea);
  const [mapBlock, setMapBlock] = useState("");
  const [assessorBlock, setAssessorBlock] = useState("");
  const [rate, setRate] = useState('');

  // Incorrect Owner Appeal
  const [legalOwner, setLegalOwner] = useState(defaultLegalOwner);
  const [newOwnerFile, setNewOwnerFile] = useState("");
  const [newOwnerFileName, setNewOwnerFileName] = useState("");

  // Inaccurate Impermeable Parcel Area
  const [impermeable, setImpermeable] = useState(defaultImpermeable);
  const [imperviousFile, setImperviousFile] = useState("");
  const [imperviousFileName, setImperviousFileName] = useState("");
  const [imperviousWarning, setImperviousWarning] = useState("");
  const [imperviousGraphics, setImperviousGraphics] = useState("");

  // Inaccurate Excluded Parcel Area
  const [excluded, setExcluded] = useState(defaultExcluded);
  const [excludedFile, setExcludedFile] = useState("");
  const [excludedFileName, setExcludedFileName] = useState("");
  const [excludedWarning, setExcludedWarning] = useState("");
  const [excludedGraphics, setExcludedGraphics] = useState("");

  // Revised Servce Agreement Charge Allocations Appeal
  const [chargeAllocations, setChargeAllocations] = useState(defaultChargeAllocations);
  const [saFile, setSAFile] = useState("");
  const [saFileName, setSAFileName] = useState("");
  const [saNum, setSANum] = useState(0);
  const [saAccounts, setSAAccounts] = useState([]);

  // Appeal Status
  const [statusMaster, setStatusMaster] = useState("initial");
  const [statusLegalOwner, setStatusLegalOwner] = useState("initial");
  const [statusImpermeable, setStatusImpermeable] = useState("initial");
  const [statusExcluded, setStatusExcluded] = useState("initial");
  const [statusSA, setStatusSA] = useState("initial");
  const [statusSubmit, setStatusSubmit] = useState(true);

  // get account related info
  useEffect(() => {
    const accountInfo = props.store.area;
    //get accounts number
    if (accountInfo.accounts) {
      let accounts = accountInfo.accounts;
      accounts.map((element) => saAccounts.push({
        f_Appeal_Account_Num: element.account,
        f_Appealed_Distb_Percent: '',
      }))
      setSAAccounts(saAccounts);
    }

    const stormwaterCharge = accountInfo.stormwaterChargeType;
    if (stormwaterCharge === 'EXEMPT') {
      setRate("Exempt");
    } else if (stormwaterCharge === 'SRT1') {
      setRate("Simplified Residential Tier 1");
    } else if (stormwaterCharge === 'SRT2') {
      setRate("Simplified Residential Tier 2");
    } else if (stormwaterCharge === 'SRT3') {
      setRate("Simplified Residential Tier 3");
    } else if (stormwaterCharge === 'STANDARD') {
      setRate("Standard");
    } else {
      setRate("Not Found");
    }

    setArea({
      S_Current_Gross_Total_Area: accountInfo.grossTotalArea,
      S_Current_Excluded_Area: accountInfo.excludedArea,
      S_Current_Permeable_Area: accountInfo.permeableArea,
      S_Current_Impermeable_Area: accountInfo.impermeableArea,
    })
    // setArea(props.store.area);
    setMapBlock(accountInfo.mapBlkLot ? accountInfo.mapBlkLot : "");
    if (accountInfo.assessorBlkLotList && accountInfo.assessorBlkLotList.length > 0) {
      setAssessorBlock(accountInfo.assessorBlkLotList[0]?.assessorBlkLot ? accountInfo.assessorBlkLotList[0].assessorBlkLot : "")
      setSANum(accountInfo.accounts.length);
    }

    const params = {
      CCB_Account: props.store.ccbAccount,
      Zipcode: props.store.zipcode
    }
    getAppealByAccount(params)
      .then((response) => {
        setErrorMessages('');
        if (response.status === 200) {
          if (response.data.masterAppeal) {
            const responseData = response.data.masterAppeal;
            setStatusMaster("submitted");
            setGeneralInfo({
              CCB_Account: responseData.ccB_Account_Number,
              Zipcode: responseData.ccB_ZipCode,
              ApplicantName: responseData.applicant_Name,
              Applicant_Phone: responseData.applicant_Phone,
              Applicant_Email: responseData.applicant_Email,
              TypeOfAccountHolder: processTypeOfAccountHolder(responseData.typeOfAccountHolder),
            })
          }

          if (response.data.legalOwnerAppeal) {
            const responseData = response.data.legalOwnerAppeal;
            setStatusLegalOwner("submitted");
            setLegalOwner({
              F_New_Legal_Owner_Name: responseData.new_legal_owner_name,
              F_LO_Appeal_Record_Date: responseData.appeal_record_date,
              F_Is_Government_Entity: responseData.isGovernmentEntity,
              F_Phone_Number: responseData.phone_number ? responseData.phone_number : "",
              F_New_Owner_Mailing_Address: responseData.address ? responseData.address : "",
              F_New_Owner_Mailing_Address_2: responseData.address2 ? responseData.address2 : "",
              F_New_Owner_Mailing_City: responseData.city ? responseData.city : "",
              F_New_Owner_Mailing_State: responseData.state ? responseData.state : "",
              F_New_Owner_Mailing_Zip: responseData.zip ? responseData.zip : "",
              F_New_Owner_Mailing_Country: responseData.country ? responseData.country : "USA",
              F_Applicant_Comments_legalOwner: responseData.applicant_comments ? responseData.applicant_comments : "",
              r_Decision: responseData.r_Decision ? responseData.r_Decision : '',
            })
          }

          if (response.data.impermeableAppeal) {
            const responseData = response.data.impermeableAppeal;
            setStatusImpermeable("submitted");
            setImpermeable({
              F_Appealed_Impermeable_Area: responseData.impermeable_area,
              F_Appealed_Permeable_Area: responseData.permeable_area,
              F_Appealed_Billable_Impermeable_Area: responseData.billable_impermeable_area,
              F_Applicant_Comments_Impermeable: responseData.applicant_comments ? responseData.applicant_comments : "",
              r_Decision: responseData.r_Decision ? responseData.r_Decision : '',
            })
          }

          if (response.data.excludedAppeal) {
            const responseData = response.data.excludedAppeal;
            setStatusExcluded("submitted");
            setExcluded({
              F_Appealed_Excluded_Area: responseData.excluded_area,
              F_Appealed_Billable_Impermeable_Area: responseData.billable_impermeable_area,
              F_Appealed_Impermeable_Area: responseData.impermeable_area,
              F_Appealed_Permeable_Area: responseData.permeable_area,
              F_Appealed_Billable_Permeable_Area: responseData.billable_Permeable_area,
              F_Applicant_Comments_Excluded: responseData.applicant_comments ? responseData.applicant_comments : "",
              r_Decision: responseData.r_Decision ? responseData.r_Decision : '',
            })
          }

          if (response.data.saAppeal) {
            const responseData = response.data.saAppeal;
            setStatusSA("submitted");
            setSAAccounts(responseData.accounts);
            setChargeAllocations({
              Accounts: responseData.accounts,
              F_Applicant_Comments_SA: responseData.f_Applicant_Comments ? responseData.f_Applicant_Comments : "",
              r_Decision: responseData.r_Decision ? responseData.r_Decision : '',
            })
          } else {
            // const account_arr = [];
            // for (let step = 0; step < saNum; step++) {
            //   account_arr.push({
            //     f_Appeal_Account_Num: props.store.area.accounts[step],
            //     f_Appealed_Distb_Percent: NaN,
            //   });
            // }
            setChargeAllocations({
              Accounts: saAccounts,
              F_Applicant_Comments_SA: ""
            })
          }
        } else {
          setGeneralInfo({
            CCB_Account: props.store.ccbAccount,
            Zipcode: props.store.zipcode,
            ApplicantName: "",
            Applicant_Phone: "",
            Applicant_Email: "",
            TypeOfAccountHolder: processTypeOfAccountHolder(),
          })
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessages('There is an error retrieving the appeal information. Please try again later.');
        }
      });
  }, [props])

  // check impermeable area info
  useEffect(() => {
    if (statusImpermeable === "initial" && impermeable.F_Appealed_Billable_Impermeable_Area > 0 && impermeable.F_Appealed_Billable_Impermeable_Area < area.S_Current_Impermeable_Area) {
      setStatusImpermeable("ready");
    }
    if (statusImpermeable === "ready" && (impermeable.F_Appealed_Billable_Impermeable_Area <= 0 || impermeable.F_Appealed_Billable_Impermeable_Area >= area.S_Current_Impermeable_Area)) {
      setStatusImpermeable("initial");
    }
  }, [statusImpermeable, impermeable, area])

  // check excluded area info
  useEffect(() => {
    const isValidArea =
      validateArea(area.S_Current_Gross_Total_Area, excluded.F_Appealed_Billable_Impermeable_Area, excluded.F_Appealed_Billable_Permeable_Area, excluded.F_Appealed_Excluded_Area)
      &&
      validateArea(area.S_Current_Gross_Total_Area, excluded.F_Appealed_Impermeable_Area, excluded.F_Appealed_Permeable_Area, excluded.F_Appealed_Excluded_Area)
    if (!isValidArea) {
      setExcludedWarning('*Please check the appealed area.');
    } else {
      setExcludedWarning("");
    }
    if (statusExcluded === "initial" && excluded.F_Appealed_Excluded_Area > area.S_Current_Excluded_Area && excluded.F_Appealed_Excluded_Area < area.S_Current_Gross_Total_Area && excluded.F_Appealed_Excluded_Area >= 0) {
      setStatusExcluded("ready");
    }
    if (statusExcluded === "ready" && (excluded.F_Appealed_Excluded_Area <= area.S_Current_Excluded_Area || excluded.F_Appealed_Excluded_Area >= area.S_Current_Gross_Total_Area || excluded.F_Appealed_Excluded_Area < 0)) {
      setStatusExcluded("initial");
    }
  }, [statusExcluded, excluded, area])

  // check button
  useEffect(() => {
    if ((statusMaster === "ready" || statusMaster === "submitted") && (statusLegalOwner === "ready" || statusImpermeable === "ready" || statusExcluded === "ready" || statusSA === "ready")) {
      setStatusSubmit(false);
    } else {
      setStatusSubmit(true);
    }
  }, [statusMaster, statusLegalOwner, statusImpermeable, statusExcluded, statusSA])

  useEffect(() => {
    const roundedImpermeableArea = Math.floor(impermeable.F_Appealed_Impermeable_Area / 100) * 100;
    setImpermeable(prev => {
      return {
        ...prev,
        F_Appealed_Billable_Impermeable_Area: roundedImpermeableArea,
        F_Appealed_Permeable_Area: Math.round(area.S_Current_Gross_Total_Area - area.S_Current_Excluded_Area - roundedImpermeableArea)
      }
    })

    if (area?.S_Current_Impermeable_Area && roundedImpermeableArea >= area.S_Current_Impermeable_Area) {
      setImperviousWarning("*The appealed impermeable area is not less than the current impermeable area. There will be no change on bill.");
    } else {
      setImperviousWarning("");
    }
  }, [impermeable.F_Appealed_Impermeable_Area, area])

  useEffect(() => {
    const isValidArea =
      validateArea(area.S_Current_Gross_Total_Area, excluded.F_Appealed_Billable_Impermeable_Area, excluded.F_Appealed_Billable_Permeable_Area, excluded.F_Appealed_Excluded_Area)
      &&
      validateArea(area.S_Current_Gross_Total_Area, excluded.F_Appealed_Impermeable_Area, excluded.F_Appealed_Permeable_Area, excluded.F_Appealed_Excluded_Area)

    if (area?.S_Current_Excluded_Area && excluded.F_Appealed_Excluded_Area <= area?.S_Current_Excluded_Area) {
      setExcludedWarning("*The appealed excluded area is not greater than the current excluded area. There will be no change on bill.");
    } else if (area?.S_Current_Gross_Total_Area && excluded.F_Appealed_Excluded_Area >= area.S_Current_Gross_Total_Area) {
      setExcludedWarning("*The appealed excluded area cannot be greater than the current gross total area.");
    } else if (!isValidArea) {
      setExcludedWarning('*Please check the appealed area.');
    } else {
      setExcludedWarning("");
    }
  }, [excluded.F_Appealed_Excluded_Area, area])

  const handleGeneralInfoChange = (event) => {
    setGeneralInfo(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      }
    })
  };

  const processTypeOfAccountHolder = (typeOfAccountHolder) => {
    let clone = {...TypeOfAccountMatrix};
    if (!typeOfAccountHolder) {
      return clone;
    }
    for (let i = 0; i < typeOfAccountHolder.length; i++) {
      clone[typeOfAccountHolder[i]] = true;
    }
    return clone;
  }

  const handleTypeofAccountChange = (event) => {
    const {name, checked} = event.target;
    let temp = generalInfo.TypeOfAccountHolder;
    temp[name] = checked;
    setGeneralInfo(prev => {
      return {
        ...prev,
        TypeOfAccountHolder: temp
      }
    })
  }

  const handlePhoneChange = (value) => {
    setGeneralInfo(prev => {
      return {
        ...prev,
        Applicant_Phone: value
      }
    })
  };

  const handleLegalOwnerChange = (event) => {
    if (event.target.name === 'F_Is_Government_Entity') {
      setLegalOwner(prev => {
        return {
          ...prev,
          [event.target.name]: event.target.checked
        }
      })
    } else {
      setLegalOwner(prev => {
        return {
          ...prev,
          [event.target.name]: event.target.value
        }
      })
    }
  };

  const handleLegalOwnerPhoneChange = (value) => {
    setLegalOwner(prev => {
      return {
        ...prev,
        F_Phone_Number: value
      }
    })
  };

  const handleLegalOwnerDateChange = (value) => {
    if (statusLegalOwner === 'submitted') return;
    if (isNaN(Date.parse(value)) || !value) {
      value = ''
    } else {
      value = value.toLocaleDateString('en-US');
    }
    setLegalOwner(prev => {
      return {
        ...prev,
        F_LO_Appeal_Record_Date: value
      }
    })
  };

  const legalOwnerFileChange = (event) => {
    if (event) {
      setNewOwnerFileName(event.target.files[0].name);
      setNewOwnerFile(event.target.files[0]);
    } else {
      setNewOwnerFileName("");
      setNewOwnerFile("");
    }
  };

  const handleImpermeableChange = (event) => {
    let updatedValue = event.target.value;
    if (event.target.name === 'F_Appealed_Impermeable_Area') {
      updatedValue = isNaN(parseFloat(updatedValue)) ? '' : parseFloat(updatedValue);
    }
    setImpermeable(prev => {
      return {
        ...prev,
        [event.target.name]: updatedValue
      }
    })
  };

  const handleImpermeableAreaChange = (value) => {
    setImpermeable(prev => {
      return {
        ...prev,
        F_Appealed_Impermeable_Area: value
      }
    })
  };

  const imperviousFileChange = (event) => {
    if (event) {
      setImperviousFileName(event.target.files[0].name);
      setImperviousFile(event.target.files[0]);
    } else {
      setImperviousFileName("");
      setImperviousFile("");
    }
  };

  const handleExcludedChange = (event) => {
    let updatedValue = event.target.value;
    if (event.target.name === 'F_Appealed_Excluded_Area') {
      updatedValue = isNaN(parseFloat(updatedValue)) ? '' : parseFloat(updatedValue);
    }
    setExcluded(prev => {
      return {
        ...prev,
        [event.target.name]: updatedValue
      }
    })
  };

  const handleExcludedAreaChange = ({ total, a, b }) => {
    // a: impermeable intersect portion
    // b: permeable intersect portion
    // F_Appealed_Net_Total_Area = area.S_Current_Gross_Total_Area - total
    // F_Appealed_Excluded_Area = total
    // F_Appealed_Impermeable_Area = area.S_Current_Impermeable_Area - a
    // F_Appealed_Billable_Impermeable_Area = Math.floor((area.S_Current_Impermeable_Area - a) / 100) * 100
    // F_Appealed_Permeable_Area = area.S_Current_Permeable_Area - b
    // F_Appealed_Billable_Permeable_Area = F_Appealed_Net_Total_Area - F_Appealed_Billable_Impermeable_Area
    const billableImpermeable = Math.floor((area.S_Current_Impermeable_Area - a) / 100) * 100;

    setExcluded(prev => {
      return {
        ...prev,
        F_Appealed_Net_Total_Area: area.S_Current_Gross_Total_Area - total,
        F_Appealed_Excluded_Area: total,
        F_Appealed_Impermeable_Area: Math.floor(area.S_Current_Impermeable_Area - a),
        F_Appealed_Billable_Impermeable_Area: billableImpermeable,
        F_Appealed_Permeable_Area: Math.floor(area.S_Current_Permeable_Area - b),
        F_Appealed_Billable_Permeable_Area: Math.floor(area.S_Current_Gross_Total_Area - total - billableImpermeable),
      }
    })
  };

  const excludedFileChange = (event) => {
    if (event) {
      setExcludedFileName(event.target.files[0].name);
      setExcludedFile(event.target.files[0]);
    } else {
      setExcludedFileName("");
      setExcludedFile("");
    }
  };

  const handleChargeAllocationsAccountChange = (value, index, type, accounts) => {
    const saAccountTemp = accounts.slice();
    let updatedValue = value.replaceAll(/[^0-9 | \.]/g, '');
    let idx = updatedValue.indexOf('.');
    if (idx !== -1) {
      updatedValue = updatedValue.slice(0, idx + 3);
    }
    saAccountTemp[index][type] = updatedValue;
    setSAAccounts(saAccountTemp);
    setChargeAllocations(prev => {
      return {
        ...prev,
        Accounts: saAccountTemp
      }
    })
  };

  const handleChargeAllocationsAccountSet = (value) => {
    setChargeAllocations(prev => {
      return {
        ...prev,
        Accounts: value
      }
    })
  };

  const handleChargeAllocationsChange = (event) => {
    setChargeAllocations(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      }
    })
  };

  const saFileChange = (event) => {
    if (event) {
      setSAFileName(event.target.files[0].name);
      setSAFile(event.target.files[0]);
    } else {
      setSAFileName("");
      setSAFile("");
    }
  };

  const statusChange = (status) => {
    if (status === "submitted") {
      return true;
    } else {
      return false;
    }
  };

  const statusReady = (status) => {
    if (status === "submitted" || status === "ready") {
      return true;
    } else {
      return false;
    }
  };

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const appendGeneralInfo = (formData, generalInfo) => {
    const temp = [];
    for (let key in generalInfo.TypeOfAccountHolder) {
      if (generalInfo.TypeOfAccountHolder[key]) {
        temp.push(key);
      }
    }
    for (let key in generalInfo) {
      if (key === "TypeOfAccountHolder") {
        temp.forEach((element, index) => {
          formData.append(`TypeOfAccountHolder[${index}]`, element);
        })
      } else {
        formData.append(key, generalInfo[key]);
      }
    }
  }

  const submitAppealbyType = async () => {
    const submitLegalOwnerData = new FormData();
    const submitImpermeableAreaData = new FormData();
    const submitExcludedAreaData = new FormData();
    const submitSAData = new FormData();

    // Legal Onwer
    if (statusLegalOwner === "ready") {
      appendGeneralInfo(submitLegalOwnerData, generalInfo);

      for (let key in legalOwner) {
        submitLegalOwnerData.append(key, legalOwner[key]);
      };

      submitLegalOwnerData.append("FileName", newOwnerFileName)
      submitLegalOwnerData.append("AttachmentFile", newOwnerFile)
      submitLegalOwnerData.append("S_Assessor_Block_Lot", assessorBlock)
      const resultLegalOnwer = await submitLegalOwnerAppeal(submitLegalOwnerData);
    };

    // Impermeable Area
    if (statusImpermeable === "ready") {

      appendGeneralInfo(submitImpermeableAreaData, generalInfo);

      for (let key in impermeable) {
        submitImpermeableAreaData.append(key, impermeable[key]);
      };

      for (let key in area) {
        submitImpermeableAreaData.append(key, area[key]);
      };

      submitImpermeableAreaData.append("FileName", imperviousFileName)
      submitImpermeableAreaData.append("AttachmentFile", imperviousFile)
      submitImpermeableAreaData.append("S_Assessor_Block_Lot_No", assessorBlock)
      submitImpermeableAreaData.append("S_Map_Block_Lot_No", mapBlock)

      const resultImpermeable = await submitImpermeableAreaAppeal(submitImpermeableAreaData);
    }

    // Excluded Area
    if (statusExcluded === "ready") {
      appendGeneralInfo(submitExcludedAreaData, generalInfo);

      for (let key in excluded) {
        submitExcludedAreaData.append(key, excluded[key]);
      };

      for (let key in area) {
        submitExcludedAreaData.append(key, area[key]);
      };

      submitExcludedAreaData.set('S_Current_Net_Total_Area', area.S_Current_Impermeable_Area + area.S_Current_Permeable_Area);
      if (excluded.F_Appealed_Net_Total_Area === 0) {
        submitExcludedAreaData.set('F_Appealed_Net_Total_Area', excluded.F_Appealed_Billable_Impermeable_Area + excluded.F_Appealed_Billable_Permeable_Area);
      }
      submitExcludedAreaData.append("FileName", excludedFileName)
      submitExcludedAreaData.append("AttachmentFile", excludedFile)
      submitExcludedAreaData.append("S_Assessor_Block_Lot_No", assessorBlock)
      submitExcludedAreaData.append("S_Map_Block_Lot_No", mapBlock)

      const resultExcluded = await submitExcludedAreaAppeal(submitExcludedAreaData);
    }

    // SA
    if (statusSA === "ready") {
      const saDataFilter = [];
      chargeAllocations.Accounts.forEach(sa => {
        if (sa.f_Appeal_Account_Num && parseFloat(sa.f_Appealed_Distb_Percent, 10) >= 0) {
          const temp = {
            F_Appeal_Account_Num: sa.f_Appeal_Account_Num,
            F_Appealed_Distb_Percent: parseFloat(sa.f_Appealed_Distb_Percent, 10)
          }
          saDataFilter.push(temp);
        }
      })

      appendGeneralInfo(submitSAData, generalInfo);

      submitSAData.append("FileName", saFileName)
      submitSAData.append("AttachmentFile", saFile)
      submitSAData.append("F_Applicant_Comments_SA", chargeAllocations.F_Applicant_Comments_SA)
      submitSAData.append("S_Map_Block_Lot_No", mapBlock)

      saDataFilter.forEach((sa, index) => {
        submitSAData.append(`Accounts[${index}][F_Appeal_Account_Num]`, sa.F_Appeal_Account_Num);
        submitSAData.append(`Accounts[${index}][F_Appealed_Distb_Percent]`, sa.F_Appealed_Distb_Percent);
      })

      const resultSA = await submitSAAppeal(submitSAData);
    }
  };

  const submitAppeal = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    submitAppealbyType().then(result => {
      setErrorMessages('');
      setSubmitting(false);
      navigate(`/appeals/confirmation/${generalInfo.CCB_Account}/${generalInfo.Zipcode}`);
    }).catch(error => {
      setAlertDialogOpen(true);
      setErrorMessages('There was an error submitting your appeal. Please try again later.');
      setSubmitting(false);
    });
  }

  return (
    <Fragment>
      <CssBaseline />
      <main>
        <div className={classes.heroContent} style={{ backgroundColor: "whitesmoke" }}>
          <Container maxWidth="md">
            <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
              Stormwater Charge Appeal Form
            </Typography>
          </Container>
        </div>
        <div >
          <Container maxWidth="md">
            <Paper className={classes.paper}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  Si necesita ayuda en español llame al (415) 551-3000. <br />
                  Kung kailangan ninyo ng tulong sa Filipino mangyaring tumawag sa (415) 551-3000. <br />
                  如果您需要中文協助、請致電 (415) 551-3000。 <br />
                </Typography>
              </Grid>
              <Box m={2} />
              <Grid item xs={12} align="left">
                <Typography>
                  Residents or businesses may appeal or request changes to their stormwater charge by completing the <LinkTextDisplay linkName={'Stormwater Charge Appeal Form'} href={form} externalLink={true} linkStyle={classes.link} /> online or on paper. <br />
                  <br />
                  The San Francisco Public Utilities Commission (SFPUC) will consider only one stormwater charge appeal for each appeal type (change in ownership of the property, error in the assessment of the property's impermeable or permeable areas., etc.), for each parcel annually (within 12 months of the date the first appeal is filed).
                </Typography>
              </Grid>
              <Box m={2} />
              <Grid item xs={12} align="left">
                <Typography>
                  Paper copies of the appeal can be mailed or delivered to: <LinkTextDisplay linkName={'Customer Service'} href={'https://sfpuc.org/about-us/contact-us'} externalLink={true} linkStyle={classes.link}/> at 525 Golden Gate Avenue, 1st Floor, San Francisco, CA 94102, 8:30 a.m. to 4:30 p.m., Monday to Friday, excluding legal holidays.
                </Typography>
              </Grid>
              <FormControl className={`${classes.formRoot} ${classes.form}`} >
                <Grid container spacing={2}>
                  <Container>
                    <GeneralInformation
                      statusMaster={statusMaster}
                      statusChange={setStatusMaster}
                      generalInfo={generalInfo}
                      handleGeneralInfoChange={handleGeneralInfoChange}
                      handlePhoneChange={handlePhoneChange}
                      handleTypeofAccountChange={handleTypeofAccountChange}
                    />
                  </Container>
                  <Grid item xs={12} align="center" style={{ backgroundColor: "whitesmoke" }}>
                    <Typography variant="h5" gutterBottom color="primary">This property is subject to the <br></br>
                      <LinkTextDisplay href='https://sfpuc.org/accounts-services/water-power-sewer-rates/stormwater-charge' linkName={`Stormwater Charge - ${rate} Rate`} linkStyle={classes.link} externalLink={true} />
                    </Typography>
                    {rate.includes("Simplified") &&
                    <Typography variant="body1" gutterBottom>
                      Simplified residential charge tiers are determined based on the net total area of your parcel size. <br />
                      Permeable and impermeable parcel area is not used in the calculation of simplified residential tier charges.
                    </Typography>
                    }
                  </Grid>

                  <Box p={2} bgcolor="background.paper">
                    <Divider variant="middle" />
                  </Box>

                  <Container>
                    <Grid container spacing={2} >
                      <LegalOwnerAppeal
                        expanded={expanded}
                        handleAccordianChange={handleAccordianChange}
                        statusLegalOwner={statusLegalOwner}
                        statusChange={setStatusLegalOwner}
                        legalOwner={legalOwner}
                        handleLegalOwnerChange={handleLegalOwnerChange}
                        handleLegalOwnerDateChange={handleLegalOwnerDateChange}
                        handleLegalOwnerPhoneChange={handleLegalOwnerPhoneChange}
                        newOwnerFileName={newOwnerFileName}
                        legalOwnerFileChange={legalOwnerFileChange}
                        assessorBlock={assessorBlock}
                      />
                    </Grid>
                  </Container>

                  <Box p={2} bgcolor="background.paper">
                    <Divider variant="middle" />
                  </Box>

                  {(!props.store.area.isSimplifiedResidential && !props.store.isExempt) &&
                    <Container>
                      <Grid container spacing={2}>
                        <ImpermeableAreaAppeal
                          expanded={expanded}
                          handleAccordianChange={handleAccordianChange}
                          statusReady={statusReady(statusImpermeable)}
                          statusChange={statusChange(statusImpermeable)}
                          statusImpermeable={statusImpermeable}
                          impermeable={impermeable}
                          handleImpermeableChange={handleImpermeableChange}
                          handleImpermeableAreaChange={handleImpermeableAreaChange}
                          setImperviousGraphics={setImperviousGraphics}
                          imperviousFileName={imperviousFileName}
                          imperviousFileChange={imperviousFileChange}
                          imperviousWarning={imperviousWarning}
                          area={area}
                          mapBlock={mapBlock}
                          assessorBlock={assessorBlock}
                        />
                      </Grid>
                    </Container>
                  }
                  <Box p={2} bgcolor="background.paper">
                    <Divider variant="middle" />
                  </Box>
                  {(!props.store.area.isSimplifiedResidential && !props.store.isExempt) &&
                    <Container>
                      <Grid container spacing={2}>
                        <ExcludedAreaAppeal
                          expanded={expanded}
                          handleAccordianChange={handleAccordianChange}
                          statusReady={statusReady(statusExcluded)}
                          statusChange={statusChange(statusExcluded)}
                          statusExcluded={statusExcluded}
                          excluded={excluded}
                          handleExcludedChange={handleExcludedChange}
                          setExcludedGraphics={setExcludedGraphics}
                          handleExcludedAreaChange={handleExcludedAreaChange}
                          excludedFileName={excludedFileName}
                          excludedFileChange={excludedFileChange}
                          excludedWarning={excludedWarning}
                          area={area}
                          mapBlock={mapBlock}
                          assessorBlock={assessorBlock}
                        />
                      </Grid>
                    </Container>
                  }

                  <Box p={2} bgcolor="background.paper">
                    <Divider variant="middle" />
                  </Box>

                  {(!props.store.isExempt && saAccounts.length > 1)
                    &&
                    <Container>
                      <Grid container spacing={2}>
                        <ServiceAgreementAppeal
                          expanded={expanded}
                          handleAccordianChange={handleAccordianChange}
                          statusSA={statusSA}
                          statusChange={setStatusSA}
                          saNum={saNum}
                          chargeAllocations={chargeAllocations}
                          handleChargeAllocationsAccountChange={handleChargeAllocationsAccountChange}
                          handleChargeAllocationsAccountSet={handleChargeAllocationsAccountSet}
                          handleChargeAllocationsChange={handleChargeAllocationsChange}
                          saFileName={saFileName}
                          saFileChange={saFileChange}
                          area={area}
                          mapBlock={mapBlock}
                          saAccounts={saAccounts}
                        />
                      </Grid>
                    </Container>}

                  <Box p={2} bgcolor="background.paper">
                    <Divider variant="middle" />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {submitting && <LinearProgress />}
                      <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" disabled={statusSubmit || submitting} onClick={submitAppeal} fullWidth >Submit the appeal</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
          </Container>
        </div>
      </main>
      <CustomDialog open={alertDialogOpen} title={'Notification'} content={errorMessages}
        actions={[
          {
            text: 'OK',
            onClick: () => {
              setAlertDialogOpen(false);
              navigate(`/appeals`);
            }
          }
        ]} />
    </Fragment>
  );
});

export default AppealFormPage;
import { useState, useEffect } from 'react';

/* MobX */
import { observer } from 'mobx-react-lite';

/* MUI */
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import form from '../../../assets/files/AppealForm-110723 Revised Ver_FINAL.pdf';
import { AreaInformation, LinkTextDisplay } from '../../data-display/data-display.component';
import { FileUploadButton } from '../../form-input/form-file-upload-button.component';

const ServiceAgreementAppeal = observer(({
  expanded,
  handleAccordianChange,
  statusSA,
  statusChange,
  saNum,
  chargeAllocations,
  handleChargeAllocationsAccountChange,
  handleChargeAllocationsAccountSet,
  handleChargeAllocationsChange,
  saFileName,
  saFileChange,
  area,
  mapBlock,
  saAccounts }) => {
  const [warning, setWarning] = useState("");
  const { Accounts, F_Applicant_Comments_SA, r_Decision } = chargeAllocations;
  const [statusMessage, setStatusMessage] = useState('');

  // check SA info
  useEffect(() => {
    let num = 0;
    let sum = 0;
    sum = saAccounts.reduce(
      (accumulator, currentValue) => accumulator + parseFloat(currentValue.f_Appealed_Distb_Percent),
      sum);
    num = saAccounts.length;
    if (statusSA === 'submitted') {
      setWarning('');
    } else {
      if (sum === 100 && num >= 2) {
        statusChange("ready");
        if (num !== saNum) {
          setWarning("*Not match number of SAs on Map Block Lot");
        } else {
          setWarning("");
        }
      } else {
        statusChange("initial");
        if (sum !== 100) {
          setWarning("*The charge distribution must add to 100% for all accounts");
        } else if (num < 2) {
          setWarning("*If there's only one account on the map block lot, then the charge will be allocated 100% to the sole account")
        }
      }
    }
  }, [statusSA, saAccounts, saNum])

  useEffect(() => {
    if (r_Decision) {
      if (r_Decision === '' || r_Decision === 'pending' || r_Decision === 'inprogress') {
        setStatusMessage(<Typography variant="h6" color="error">There is a current appeal pending. Please contact us at (415)-551-3000 before submitting a new request.</Typography>)
      } else {
        setStatusMessage(<Typography variant="h6" color="error">A previous appeal submission has already been determined for this appeal type within the past 12 months from the date the initial appeal was filed. <br />
          <br />
          The San Francisco Public Utilities Commission considers only one stormwater charge appeal for each appeal type on each parcel annually. Please contact us at stormwaterappeals@sfwater.org for any questions.
        </Typography>)
      }
    }
  }, [r_Decision])


  return (
    <Accordion expanded={expanded === 'panel4'} onChange={handleAccordianChange('panel4')}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="SAChargeAllocAppeal" style={{ backgroundColor: "whitesmoke" }}>
        <Typography variant="h6" gutterBottom color="primary">
          <Checkbox
            checked={statusSA === "submitted" || statusSA === "ready"}
            disabled={statusSA === "submitted"}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          Enter Information for Change how the stormwater charge is split between multiple accounts on a property
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Typography variant="body1" component={'div'}>
            By default, the stormwater charge for a property is equally split across all SFPUC accounts with an active wastewater agreement for that property. However, customers may voluntarily agree to a different allocation for any reason.<br />
            <br />
            <b>All affected SFPUC account holders must agree to the requested split of the stormwater charge (<LinkTextDisplay linkName={'Letter of Agreement'} href={form + '#page=8'} externalLink={true} />).</b> The agreement must clearly define the agreed-upon area that will be assigned to each customer. The area may be identified either as gross percentage of the overall parcel area or a detailed delineation of the total permeable and impermeable area assigned to each account. Once approved by the SFPUC, the new percentage split will be used to calculate all future stormwater charges.<br />
            <br />
          </Typography>
          <Grid item xs={12}>
            {statusSA === "submitted" && statusMessage}
          </Grid>
          <AreaInformation
            data={{
              "Map Block Lot": mapBlock,
              "Number of Service Agreements": saAccounts?.length ? saAccounts.length : saNum,
            }}
            size={6}
            showTitle={false}
          />
          <Grid item xs={6} align="center">
            <Typography>Account Number</Typography>
          </Grid>
          <Grid item xs={6} align="center">
            <Typography>Charge (%)</Typography>
          </Grid>

          {saAccounts.length > 0 && saAccounts.map((element, index) =>
          (<Grid item xs={12} key={index}>
            <Grid container spacing={2}>
              <Grid item xs={6} align="center">
                <TextField
                  name="acc_no"
                  variant="outlined"
                  fullWidth
                  label={`Account Number ${index + 1}`}
                  value={element.f_Appeal_Account_Num}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} align="center">
                <TextField
                  name="acc_charge"
                  variant="outlined"
                  label="Charge Percentage"
                  fullWidth
                  value={element.f_Appealed_Distb_Percent ? element.f_Appealed_Distb_Percent : ''}
                  onChange={event => { handleChargeAllocationsAccountChange(event.target.value, index, "f_Appealed_Distb_Percent", saAccounts) }}
                  InputProps={{ readOnly: statusSA === "submitted", }}
                />
              </Grid>
            </Grid>
          </Grid>)
          )}
          {warning &&
            <Grid item xs={12} align="right" >
              <Typography color="error">{warning}</Typography>
            </Grid>
          }
          <Grid item xs={12} align="left">
            <FileUploadButton
              description={"Attach Stormwater Charge Split Agreement Document"}
              fileChange={saFileChange}
              instruction={'Supporting documents may include; Change in Stormwater Charge Split Letter of Agreement. Please submit all documents as one file, with .pdf, .jpg, or .jpeg extension and with a file size of less than 200MB.'}
              disabled={statusSA === "submitted"} />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField name="F_Applicant_Comments_SA" variant="outlined" label="Applicant Comments" fullWidth multiline
              value={F_Applicant_Comments_SA} onChange={handleChargeAllocationsChange} disabled={statusSA === "submitted"} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
})

export default ServiceAgreementAppeal;